import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import "./App.css";

const PersonalPaylink = lazy(() => import("./pages/PersonalPaylink"));
const ProductPaylink = lazy(() => import("./pages/ProductPaylink"));
const Receipt = lazy(() => import("./pages/Receipt"));

function App() {
  return (
    <Suspense
      fallback={
        <div id="preloader">
          <div className="sk-three-bounce">
            <div className="sk-child sk-bounce1"></div>
            <div className="sk-child sk-bounce2"></div>
            <div className="sk-child sk-bounce3"></div>
          </div>
        </div>
      }
    >
      
      <Routes>
        <Route
          path="/personal-link/:paylinkId/:signature"
          element={<PersonalPaylink />}
        />
        <Route
          path="/product-link/:paylinkId/:signature"
          element={<ProductPaylink />}
        />
        <Route
          path="/payment/receipt/:authority/:signature"
          element={<Receipt />}
        />
      </Routes>
    </Suspense>
  );
}

export default App;
